
import React, { useState } from 'react'
import "./PurchaseModal.sass"
import { injectStripe } from 'react-stripe-elements';
import CheckoutForm from './CheckoutForm';
import Button from '../../shared/button/Button';
import Input from '../../shared/input/components/Input';
import Modal from '../../shared/modal/Modal';
import { usePurchase } from '../hocs/usePurchase';
import { rootConfig } from '../../../../../../shared/config'

function usePurchaseModal () {
  const [formFields, setFormFields] = useState({ email: '', name: '' });

  const onFormFieldChanged = (fieldName, value) => {
    setFormFields({
      ...formFields,
      [fieldName]: value
    })
  }

  return { 
    models: {
      formFields
    },
    operations: {
      onFormFieldChanged
    }
  }
}

function PurchaseModal (props) {
  const { stripe } = props;
  const { 
    models: purchaseModalModels, 
    operations: purchaseModalOperations 
  } = usePurchaseModal();

  const { 
    models, 
    operations 
  } = usePurchase();

  const { email, name } = purchaseModalModels.formFields
  
  return (
    <Modal 
      isOpen={models.modal.isOpen} 
      loading={models.modal.isPurchasing}
      onClose={() => operations.closeModal()}
    >
      <div className="purchase-modal">
        <section>
          <h2>Learn to write testable, flexible & maintainable code</h2>
          <ul>
            <li>🔥 Write testable, flexible, maintainable TypeScript w/ Node.js</li>
            <li>🔥 Prevent unmaintainable code. Identify bad design and refactor towards a good one</li>
            <li>🔥 Use OOP + Domain modeling to tackle apps of <i>any</i> complexity</li>
            <li>🔥 Structure business logic away from dependencies, frameworks, tools</li>
            <li>🔥 Integrate Domain models into the heart of your application</li>
            <li>🔥 Use the SOLID principles to prevent tightly coupled and untestable code</li>
            <li>🔥 Separate the concerns of your application into domain, application and infrastructure layers</li>
          </ul>
        </section>
        <section>
          {models.purchaseQuantity === 1 ? (
            <>
              <h2>Get lifetime access to the wiki/book</h2>
              <p>Over 600 pages. Seriously. You'll get instant access on 👩‍💻&nbsp; Web + 📱&nbsp; Mobile + 📝&nbsp; PDF + 📖&nbsp; EPUB </p>
            </>
          ) : (
            <>
              <h2>Get {models.purchaseQuantity} lifetime access copies to the book</h2>
              <p>Over 600 pages. Seriously. You'll get instant access on 👩‍💻&nbsp; Web + 📱&nbsp; Mobile + 📝&nbsp; PDF + 📖&nbsp; EPUB </p>
            </>
          )}
          
          <Input
            placeholder="email@example.com"
            onChange={(e) => purchaseModalOperations.onFormFieldChanged('email', e.target.value)}
          />
          <Input
            placeholder="Your name"
            onChange={(e) => purchaseModalOperations.onFormFieldChanged('name', e.target.value)}
          />
          <CheckoutForm/>
          <Button
            text={models.purchaseQuantity === 1 ? `Buy SOLID   |   $${rootConfig.price} (${rootConfig.percentageOff}% off)` : `Buy ${models.purchaseQuantity} copies of SOLID   |   $${operations.getPricingFor(models.purchaseQuantity)}`}
            onClick={() => operations.makeStripePurchase({ stripe, name, email, copies: models.purchaseQuantity })}
          />
          <a className="payment-secure" href="https://stripe.com/docs/security/stripe">🔐 Secure payment by Stripe</a>
          <p className="or" style={{ textAlign: 'center' }}>or</p>
          <div id="paypal-button-container"></div>
        </section>
      </div>
    </Modal>
  )
}

export default injectStripe(PurchaseModal);