
import React from 'react'
import "./Hero.sass"
import Button from '../shared/button/Button'
import { rootConfig } from '../../../../../shared/config'
import NumReaders from './NumReaders'
import { usePurchase } from '../purchase/hocs/usePurchase'
import { graphql, Link, useStaticQuery } from 'gatsby'
import GatsbyLink, { navigate } from 'gatsby-link'
const ebookCover = require('../../images/frame.png');

const GET_NUM_READERS = graphql`
  {
    meta(type: {eq: "numPurchases"}) {
      type
      value
    }
  }
`

const Hero = () => {
  const { meta } = useStaticQuery(GET_NUM_READERS);

  return (
    <section className="hero">
      <div className="content-container container-large">
        <div className="hero-column-left">
        <p className="title">NOW AVAILABLE</p>
          <h1>Learn to write <span>testable</span>, <span>flexible</span>, <span>maintainable</span> code</h1>
          <p>
            Ever wonder why your code gets <i>worse</i> <b>instead of better over time</b>? 
            This wiki shows <b>professional software developers </b>  
            the essential <b>software design</b>, <b>architecture</b>, and <b>testing</b> best practices 
            they <i>didn’t</i> teach you in school.
          </p>
          <div className="cta-container">
            <div className="cta-buttons">
              <div>
                <Button 
                  onClick={() => {
                    navigate('#pricing')
                  }}
                  text={`Get the book/wiki`}
                />
                <NumReaders number={6249}/>
              </div>

              <a className="intro-button" target="_blank" href="https://wiki.solidbook.io/Introduction-872fd41c47f54a61a318c6729ed7c0bd">Read the intro</a>
            </div>
            <br/>
          </div>
        </div>
  
        <div className="ebook-cover-container">
          <img src={ebookCover}/>
        </div>
      </div>
    </section>
  )
}

export default Hero;